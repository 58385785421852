import { createStore } from 'vuex'
import axios from 'axios'
import { utils } from '@/utils'

const CART_STORAGE_KEY = 'cartItems'

export default createStore({
  state: {
    cartItems: JSON.parse(localStorage.getItem(CART_STORAGE_KEY)) || [],
    searchQuery: '',
    products: [],
    loading: false,
    error: null,
    categories: [],
    paginationData: null
  },
  
  mutations: {
    SET_CART_ITEMS(state, items) {
      state.cartItems = items
    },
    
    ADD_TO_CART(state, product) {
      const existingItem = state.cartItems.find(item => item.id === product.id)
      if (existingItem) {
        existingItem.quantity = product.quantity
      } else {
        state.cartItems.push({ ...product })
      }
      // Save to localStorage
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(state.cartItems))
    },
    
    REMOVE_FROM_CART(state, productId) {
      state.cartItems = state.cartItems.filter(item => item.id !== productId)
      // Save to localStorage
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(state.cartItems))
    },
    
    CLEAR_CART(state) {
      state.cartItems = []
      // Clear localStorage
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify([]))
    },
    
    UPDATE_CART_ITEM_QUANTITY(state, { id, quantity }) {
      const item = state.cartItems.find(i => i.id === id)
      if (item) {
        item.quantity = quantity
        // Save to localStorage
        localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(state.cartItems))
      }
    },
    
    SET_SEARCH_QUERY(state, query) {
      state.searchQuery = query;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_PAGINATION(state, data) {
      state.paginationData = data;
    }
  },
  
  actions: {
    initializeCart({ commit }) {
      try {
        const savedCart = localStorage.getItem(CART_STORAGE_KEY)
        if (savedCart) {
          const cartItems = JSON.parse(savedCart)
          if (Array.isArray(cartItems)) {
            commit('SET_CART_ITEMS', cartItems)
          }
        }
      } catch (error) {
        console.error('Error loading cart:', error)
        localStorage.removeItem(CART_STORAGE_KEY)
      }
    },
    
    addToCart({ commit }, product) {
      commit('ADD_TO_CART', product)
    },
    
    removeFromCart({ commit }, productId) {
      commit('REMOVE_FROM_CART', productId)
    },
    
    clearCart({ commit }) {
      commit('CLEAR_CART')
    },
    
    updateCartItemQuantity({ commit }, payload) {
      commit('UPDATE_CART_ITEM_QUANTITY', payload)
    },
    async fetchProducts({ commit }, payload) {
      commit('SET_LOADING', true);
    
      // If there's a search query, set category to null
      const category = payload?.searchQuery ? null : payload?.category;

      try {
        const response = await axios.post(
          'https://kjsliqour.shop/api/frontend/product/category-wise-products',
          {
            page: payload?.page || 1,
            status: 5,
            sort_by: null,
            category: category,
            name: payload?.searchQuery || null,
            brand: [],
            variation: [],
            min_price: null,
            max_price: null
          },
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'x-api-key': ''
            }
          }
        );

        if (response.data?.data) {
          const formattedProducts = response.data.data.products.map(product => ({
            ...product,
            formattedPrice: utils.formatPrice(product.wholesale_price || product.price || 0),
            imageUrl: utils.getImageUrl(product.cover)
          }));
          commit('SET_PRODUCTS', formattedProducts);
          commit('SET_PAGINATION', {
            current_page: response.data.data.current_page,
            last_page: response.data.data.last_page,
            per_page: response.data.data.per_page,
            total: response.data.data.total
          });
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        commit('SET_ERROR', 'Failed to load products');
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async fetchCategories({ commit }) {
      try {
        const response = await axios.get(
          'https://kjsliqour.shop/api/frontend/product-category', 
          {
            params: {
              paginate: 0,
              order_column: 'id',
              order_type: 'asc',
              parent_id: 11,
              status: 5
            },
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'x-api-key': ''
            }
          }
        );

        if (response.data?.data) {
          // Map the categories to include image URLs
          const formattedCategories = response.data.data.map(category => ({
            ...category,
            imageUrl: category.thumb ? `https://kjsliqour.shop${category.thumb}` : null
          }));
          commit('SET_CATEGORIES', formattedCategories);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    }
  },
  
  getters: {
    cartItemCount: state => {
      return state.cartItems.reduce((total, item) => total + item.quantity, 0)
    },
    
    cartTotal: state => {
      return state.cartItems.reduce((total, item) => total + (item.price * item.quantity), 0)
    },
    
    filteredProducts: (state) => {
      if (!state.products) return [];
      
      const query = state.searchQuery.toLowerCase().trim();
      if (!query) return state.products;
      
      return state.products.filter(product => 
        product.name.toLowerCase().includes(query) ||
        (product.description && product.description.toLowerCase().includes(query))
      );
    }
  }
}) 