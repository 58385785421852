<template>
  <div v-if="isVisible" class="modal-overlay">
    <div class="modal-content">
      <button @click="handleClose" class="close-button" title="Close modal">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="close-icon">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <div class="payment-status">
        <div v-if="icon" v-html="icon" class="status-icon"></div>
        <div v-else-if="!isExpired" class="spinner-container">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"/>
          </svg>
        </div>
        
        <div v-else class="expired-icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>

        <h3 class="status-title" :class="{ 'text-error': isExpired || status === 'failed' }">
          {{ status || (isExpired ? 'Payment Time Expired' : 'Waiting for Payment') }}
        </h3>
        
        <p class="status-message" :class="{ 'error': isExpired || status === 'failed' }" v-if="!isExpired">
          {{ message || 'Please complete your M-PESA payment' }}
        </p>
        <p class="status-message error" v-else>
          {{ message || 'The payment session has expired. Please try again.' }}
        </p>
        
        <div v-if="showClock" class="timer" :class="{ 'expired': isExpired }">
          {{ isExpired ? '0:00' : formatTime }}
        </div>

        <div class="phone-number">
          <span class="label">M-PESA Push sent to:</span>
          <span class="number">{{ formattedPhone }}</span>
        </div>

        <div class="instructions">
          <p>1. You will receive an M-PESA prompt on your phone from BOBPLUS AFRICA</p>
          <p>2. Enter your M-PESA PIN to complete payment</p>
          <p>3. Wait for confirmation message</p>
        </div>

        <!-- Show retry button when expired -->
        <button 
          v-if="status === 'failed' || isExpired"
          @click="handleRetry"
          class="retry-button"
          :disabled="isRetrying"
          :class="{ 'is-loading': isRetrying }"
        >
          <span class="button-text" :class="{ 'invisible': isRetrying }">
            Retry Payment
          </span>
          <span v-if="isRetrying" class="loading-spinner">
            <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
        </button>

        <div class="order-details">
          <div class="detail-row">
            <span>Amount:</span>
            <span class="amount">KSH {{ formatPrice(currentAmount) }}</span>
          </div>
          <div class="detail-row">
            <span>Order No:</span>
            <span class="order-number">{{ currentOrderSerial }}</span>
          </div>
          <div class="detail-row small">
            <span>Reference ID:</span>
            <span class="order-id">#{{ currentOrderId }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentModal',
  
  data() {
    return {
      isVisible: false,
      timeLeft: 45,
      timer: null,
      currentOrderId: '',
      currentOrderSerial: '',
      currentAmount: 0,
      currentPhone: '',
      isExpired: false,
      showClock: true,
      status: 'pending',
      message: '',
      icon: null,
      isRetrying: false,
    }
  },

  computed: {
    formatTime() {
      const minutes = Math.floor(this.timeLeft / 60);
      const seconds = this.timeLeft % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
    
    formattedPhone() {
      // Remove any spaces, dashes, or other characters
      let cleanPhone = this.currentPhone.replace(/\D/g, '');
      
      // Remove leading 0 if present
      if (cleanPhone.startsWith('0')) {
        cleanPhone = cleanPhone.substring(1);
      }
      
      // Add 254 prefix if not present
      if (!cleanPhone.startsWith('254')) {
        cleanPhone = '254' + cleanPhone;
      }
      
      // Format for display: 254 XXX XXX XXX
      return cleanPhone.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
    }
  },
  
  methods: {

    hide() {
      this.isVisible = false;
      this.stopTimer();
    },

    startTimer() {
      this.timeLeft = 45;
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.stopTimer();
          this.isExpired = true;
          this.$emit('timeout');
        }
      }, 1000);
    },

    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },

    formatPrice(price) {
      return price.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },

    handleClose() {
      if (confirm('Are you sure you want to close? Your payment may still be processing.')) {
        this.hide();
        this.$emit('close');
      }
    },

    retryPayment() {
      this.isExpired = false;
      this.startTimer();
      this.$emit('retry', {
        orderId: this.currentOrderId,
        orderSerial: this.currentOrderSerial,
        amount: this.currentAmount,
        phone: this.currentPhone
      });
    },

    updateModalState({ status, message, showClock, icon }) {
      this.status = status;
      this.message = message;
      this.showClock = showClock;
      this.icon = icon;
      
      if (status === 'failed') {
        this.stopTimer();
        this.isExpired = true;
      }
    },

    resetTimer() {
      // Reset timer state
      this.isExpired = false;
      this.showClock = true;
      this.timeLeft = 45; // Reset to 45 seconds
      this.startTimer(); // Restart the timer
    },

    show({ orderId, orderSerial, amount, phone }) {
      // Reset all states
      this.isExpired = false;
      this.isVisible = true;
      this.showClock = true;
      this.status = 'pending';
      this.message = '';
      this.icon = null;
      
      // Update order details with correct property names
      this.currentOrderId = orderId;
      this.currentOrderSerial = orderSerial;
      this.currentAmount = amount;
      this.currentPhone = phone;

      // Start fresh timer
      this.timeLeft = 45;
      this.startTimer();
    },

    resetAndShow({ orderId, orderSerial, amount, phone }) {
      this.isExpired = false;
      this.isVisible = true;
      this.showClock = true;
      this.status = 'pending';
      this.message = '';
      this.icon = null;
      
      // Update order details with correct property names
      this.currentOrderId = orderId;
      this.currentOrderSerial = orderSerial;
      this.currentAmount = amount;
      this.currentPhone = phone;

      this.startTimer();
    },

    async handleRetry() {
      this.isRetrying = true;
      this.updateModalState({
        status: 'retrying',
        message: 'Processing payment...',
        showClock: false,
        icon: `<svg class="animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>`
      });
      
      try {
        await this.$emit('retry');

      } finally {
        this.isRetrying = false;
      }
    }
  },

  beforeUnmount() {
    this.stopTimer();
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.modal-content {
  position: relative;
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.3s ease;
}

.payment-status {
  text-align: center;
}

.status-title {
  margin: 1rem 0;
  color: #1f2937;
  font-size: 1.5rem;
  font-weight: 600;
}

.status-message {
  color: #6b7280;
  margin-bottom: 1.5rem;
}

.timer {
  font-size: 2rem;
  font-weight: 600;
  color: #10b981;
  margin: 1rem 0;
  font-family: monospace;
}

.instructions {
  text-align: left;
  margin: 1.5rem 0;
  padding: 1rem;
  background: #f3f4f6;
  border-radius: 0.5rem;
}

.instructions p {
  margin: 0.5rem 0;
  color: #4b5563;
  font-size: 0.9rem;
}

.order-details {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background: #f9fafb;
  border-radius: 0.5rem;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.75rem 0;
  color: #4b5563;
}

.detail-row.small {
  font-size: 0.875rem;
  color: #6b7280;
}

.order-number {
  font-family: monospace;
  font-weight: 600;
  color: #1f2937;
  letter-spacing: 0.05em;
}

.order-id {
  color: #6b7280;
  font-family: monospace;
}

.amount {
  font-weight: 600;
  color: #10b981;
}

/* Spinner Animation */
.spinner-container {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  width: 100%;
}

.path {
  stroke: #10b981;
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.phone-number {
  background: #f3f4f6;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.phone-number .label {
  color: #6b7280;
  font-size: 0.875rem;
}

.phone-number .number {
  color: #1f2937;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: monospace;
  letter-spacing: 0.05em;
}

/* Add a subtle highlight effect */
.phone-number .number {
  background: #ffffff;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e5e7eb;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 10;
}

.close-button:hover {
  background: #f3f4f6;
}

.close-button:active {
  background: #e5e7eb;
}

.close-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #6b7280;
}

/* Add focus styles for accessibility */
.close-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #e5e7eb;
}

/* Optional: Add media query for mobile devices */
@media (max-width: 640px) {
  .close-button {
    top: 0.75rem;
    right: 0.75rem;
  }

  .close-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.retry-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  padding: 0.75rem 1.5rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.retry-button.is-loading {
  background-color: #60a5fa;
  cursor: not-allowed;
}

.retry-button:hover {
  background-color: #2563eb;
}

.retry-button:active {
  transform: translateY(0);
}

.retry-button svg {
  width: 1.25rem;
  height: 1.25rem;
}

.expired-icon {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  color: #ef4444;
}

.expired-icon svg {
  width: 100%;
  height: 100%;
}

.timer.expired {
  color: #ef4444;
}

.text-error {
  color: #ef4444;
}

.status-message.error {
  color: #ef4444;
}

/* Add focus styles for accessibility */
.retry-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #10b981;
}

.status-icon {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.status-icon svg {
  width: 100%;
  height: 100%;
}

.status-icon.error svg {
  color: #ef4444;
}

.status-icon.warning svg {
  color: #f59e0b;
}

.retry-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button-text {
  transition: opacity 0.2s ease;
}

.button-text.invisible {
  opacity: 0;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-icon.success {
  color: #10b981; /* Green color for success */
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
  animation: checkmarkCircle 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.status-icon.success path {
  stroke-dasharray: 50;
  stroke-dashoffset: 0;
  animation: checkmark 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes checkmark {
  0% {
    stroke-dashoffset: 50;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

@keyframes checkmarkCircle {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-content.success {
  border-color: #10b981;
}

.status-message.success {
  color: #10b981;
  font-weight: 500;
}
</style> 