<template>
  <div id="app">
    <header class="header">
      <div class="header-content">
        <div class="top-bar">
          <div class="logo">
            <img src="@/assets/images/mybeer-logo.png" alt="myBeer Logo" class="google-logo">
          </div>
          <div class="mobile-search">
            <div class="search-wrapper">
              <input
                type="text"
                :value="searchQuery"
                @input="updateSearch"
                placeholder="Search products..."
                class="search-input"
              >
              <button 
                v-if="searchQuery" 
                @click="clearSearch" 
                class="clear-search"
                aria-label="Clear search"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="clear-svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <button class="cart-button" @click="toggleCart">
            <div class="cart-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" 
                />
              </svg>
              <span class="cart-badge" v-show="cartItemCount > 0">{{ cartItemCount }}</span>
            </div>
          </button>
        </div>

        <div class="header-info">
          <div class="contact-info">
           For support call us : <a href="tel:+254707491515">+254 707 491 515</a>
          </div>
          <div class="delivery-info">
             Enjoy free delivery for order above KSH 12,500
          </div>
        </div>
      </div>
    </header>

    <div class="main-content">
      <div class="product-section">
        <ProductSection />
      </div>
      <div class="cart-section">
        <CartSection />
      </div>
    </div>

    <ShoppingCart ref="shoppingCart" />
  </div>
</template>

<style>
/* ... existing styles ... */

.header {

  top: 0;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.header-content {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.header-info {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #374151;
}

.contact-info a {
  color: #DC2626;
  text-decoration: none;
  font-weight: 600;
}

.delivery-info {
  color: #374151;
  font-size: 0.9rem;
  white-space: nowrap;
}

.logo {
  height: 80px;
  display: flex;
  align-items: center;
}

.google-logo {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.cart-button {
  position: relative;
  background: #f3f4f6;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cart-button:hover {
  background: #e5e7eb;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.cart-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
}
.cart-icon svg {
  width: 28px;
  height: 28px;
  color: #374151;
}

.cart-badge {
  position: absolute;
  top: -23px;
  right: -37px;
  background: #DC2626;
  color: white;
  font-size: 14px;
  font-weight: 600;
  height: 24px;
  min-width: 24px;
  padding: 0 6px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(220, 38, 38, 0.3);
  z-index: 1;
  animation: badgeBounce 1s ease infinite;
}

/* Bounce animation */
@keyframes badgeBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

/* Pop animation when badge first appears */
@keyframes badgePop {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Add animation when cart count changes */
.cart-badge-updated {
  animation: badgePop 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), 
             badgeBounce 1s ease infinite;
}

/* Optional: Pause bounce animation on hover */
.cart-button:hover .cart-badge {
  animation-play-state: paused;
}

/* Optional: Add a subtle gradient for more depth */
.cart-badge {
  background: linear-gradient(145deg, #EF4444, #DC2626);
}

/* Optional: Add hover state for the button that affects badge */
.cart-button:hover .cart-badge {
  transform: scale(1.1);
}

@keyframes badgePop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Optional: Add pulse animation when cart count changes */
@keyframes badgePulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.cart-badge-updated {
  animation: badgePulse 0.3s ease;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
    .cart-button{
        display: block;
    }

  .cart-icon svg {
    width: 22px;
    height: 22px;
  }

  .cart-badge {
    font-size: 12px;
    height: 20px;
    min-width: 20px;
    top: -6px;
    right: -12px;
    padding: 0 4px;
  }
}

.main-content {
  display: flex;
  height: calc(100vh - 120px); /* Subtract header height */
  overflow: hidden;
}

.product-section {
  flex: 3; /* Takes up 75% of the space */
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0rem;
}

.cart-section {
  flex: 1; /* Takes up 25% of the space */

  background: #f8f9fa;
}
/* Responsive design */
@media (max-width: 1640px) {
    .cart-section{
        display: none;
    }
}

/* Show cart button only on mobile */
.cart-button {
    display: none; /* Hidden by default on desktop */
}

@media (max-width: 1640px) {
    .cart-button {
        display: flex; /* Show on mobile */
    }
}

/* Responsive design */
@media (max-width: 1024px) {
    .cart-section{
        display: none;
    }
  
  .main-content {
    flex-direction: column;
  }

  .product-section {
    flex: 1;
    height: 75vh;
  }

  .cart-section {
    flex: none;
    height: 25vh;
    border-left: none;
    border-top: 1px solid #e5e7eb;
  }

  
}


/* Custom scrollbar for sections */
.product-section::-webkit-scrollbar,
.cart-section::-webkit-scrollbar {
  width: 6px;
}

.product-section::-webkit-scrollbar-track,
.cart-section::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.product-section::-webkit-scrollbar-thumb,
.cart-section::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}
@media (max-width: 1068px) {
  .header-info {
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    text-align: center;
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    height: auto;
    padding-bottom: 0;
  }

  .top-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .header-info {
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    text-align: center;
  }

  .contact-info {
    order: 1;
  }

  .delivery-info {
    order: 2;
    width: 100%;
    text-align: center;
    padding: 0.5rem 0;
    background: #f8fafc;
    border-top: 1px solid #e2e8f0;
    font-size: 0.85rem;
  }
}

.mobile-search {
  display: none; /* Hidden by default on desktop */
  flex: 1;
  margin: 0 1rem;
}

.search-wrapper {
  position: relative;
  width: 100%;
  background: #f3f4f6;
  border-radius: 50px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  border: 1px solid #e5e7eb;
}

.search-wrapper:hover {
  background: #f8f9fa;
}

.search-wrapper:focus-within {
  background: #ffffff;
  border-color: #c6c6c6;
  box-shadow: 0 0 0 4px rgba(34, 197, 94, 0.1);
}

.search-input {
  width: 100%;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 50px;
  font-size: 0.875rem;
  color: #374151;
  background: transparent;
  outline: none;
}

.search-input::placeholder {
  color: #6d6d6d;
}

.clear-search {
  position: absolute;
  right: 1rem;
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.clear-search:hover {
  background-color: #e5e7eb;
  color: #ef4444;
}

.clear-svg {
  width: 1.125rem;
  height: 1.125rem;
}

/* Update mobile styles */
@media (max-width: 768px) {
  .mobile-search {
    display: block; /* Show on mobile */
  }

  .top-bar {
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .logo {
    height: 60px; /* Reduce logo size on mobile */
  }

  .search-input {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  .clear-search {
    right: 0.75rem;
  }

  .clear-svg {
    width: 1rem;
    height: 1rem;
  }
}
</style>

<script>
import ProductSection from '@/components/ProductSection.vue'
import ShoppingCart from '@/components/ShoppingCart.vue'
import CartSection from '@/components/CartSection.vue'
import { auth } from './services/auth';
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    ProductSection,
    ShoppingCart,
    CartSection
  },
  computed: {
    cartItemCount() {
      return this.$store.state.cartItems?.reduce((total, item) => total + item.quantity, 0) || 0
    },
    ...mapState(['searchQuery'])
  },
  methods: {
    toggleCart() {
      this.$refs.shoppingCart.toggleCart();
    },
    updateSearch(event) {
      this.$store.commit('SET_SEARCH_QUERY', event.target.value);
    },
    clearSearch() {
      this.$store.commit('SET_SEARCH_QUERY', '');
    }
  },
  watch: {
    // Optional: Add pulse animation when cart count changes
    cartItemCount(newVal, oldVal) {
      if (newVal !== oldVal) {
        const badge = document.querySelector('.cart-badge');
        if (badge) {
          badge.classList.remove('cart-badge-updated');
          void badge.offsetWidth; // Trigger reflow
          badge.classList.add('cart-badge-updated');
        }
      }
    }
  },
  async created() {
    try {
      await auth.login();
    } catch (error) {
      console.error('Failed to login:', error);
    }
  }
}
</script> 
