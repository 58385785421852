import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import './assets/styles/global.css'

// Load Google Places API
const loadGooglePlacesAPI = () => {
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyASZysLA6VhqKt5KRmusbRhyvPEVx5bBrs&libraries=places`
  script.async = true
  script.defer = true
  document.head.appendChild(script)
}

loadGooglePlacesAPI()

// Create and mount the Vue application
const app = createApp(App)

// Use Vuex store
app.use(store)

// Mount the app
app.mount('#app') 