export const deliveryCharges = {
  // Return 0 if cart total is above threshold
  calculateDeliveryCharge(region, cartTotal, shippingMethod) {
    // Return 0 if pickup is selected
    if (shippingMethod === 'pickup') return 0;

    // Return 0 if cart total is above threshold
    if (cartTotal >= 11000) return 0;

    // Define delivery charges by region
    const regionCharges = {
      'Westlands': 160,
      'Spring Valley': 160,
      'Loresho': 160,
      'Mountain View': 160,
      'Riverside Drive': 160,
      'Kilimani': 160,
      'Yaya Centre': 160,
      'Adams Arcade': 160,
      'Prestige Plaza': 160,
      'Ngong Road': 100,
      'Hurlingham': 160,
      'Jamhuri': 120,
      'Dagoretti Corner': 100,
      'Kawangware': 100,
      'Waithaka': 160,
      'Lavington': 160,
      'Kibera': 160,
      'Valley Arcade': 160,
      'Denis Pritt Road': 160,
      'Kileleshwa': 160,
      'Karen': 200,
      'Langata': 160,
      'Ngong Town': 250,
      'Hardy': 200,
      'The Hub Karen': 200,
      'Bomas of Kenya': 250,
      'Ongata Rongai': 350,
      'Mbagathi': 200,
      'Kikuyu': 150,
      'Kinoo': 100,
      'Muthiga': 100,
      'Gitaru': 120,
      '87 Market': 100,
      'Zambezi': 160,
      'Uthiru': 120,
      'Muguga': 120,
      'Upper Hill': 200
    };

    // Return the delivery charge for the selected region, or default to 100
    return regionCharges[region] || 100;
  },

  // Get all regions with their charges
  getAllRegionCharges() {
    return {
      'Westlands': 160,
      'Spring Valley': 160,
      'Loresho': 160,
      'Mountain View': 160,
      'Riverside Drive': 160,
      'Kilimani': 160,
      'Yaya Centre': 160,
      'Adams Arcade': 160,
      'Prestige Plaza': 160,
      'Ngong Road': 100,
      'Hurlingham': 160,
      'Jamhuri': 120,
      'Dagoretti Corner': 100,
      'Kawangware': 100,
      'Waithaka': 160,
      'Lavington': 160,
      'Kibera': 160,
      'Valley Arcade': 160,
      'Denis Pritt Road': 160,
      'Kileleshwa': 160,
      'Karen': 200,
      'Langata': 160,
      'Ngong Town': 250,
      'Hardy': 200,
      'The Hub Karen': 200,
      'Bomas of Kenya': 250,
      'Ongata Rongai': 350,
      'Mbagathi': 200,
      'Kikuyu': 150,
      'Kinoo': 100,
      'Muthiga': 100,
      'Gitaru': 120,
      '87 Market': 100,
      'Zambezi': 160,
      'Uthiru': 120,
      'Muguga': 120,
      'Upper Hill': 200
    };
  },

  // Get charge for specific region
  getChargeForRegion(region) {
    const charges = this.getAllRegionCharges();
    return charges[region] || 100;
  },

  // Check if region exists
  isValidRegion(region) {
    const charges = this.getAllRegionCharges();
    return region in charges;
  }
};

export default deliveryCharges; 