<template>
  <!-- Overlay -->
  <div v-if="isCartOpen" class="cart-overlay" @click="closeCart"></div>
  
  <!-- Cart Container -->
  <div class="cart-container" :class="{ 'cart-open': isCartOpen }">
    <!-- Cart Header -->
    <div class="cart-header">
      <div class="header-content">
        <h2>My Crate {{ cartItems.length ? `(${totalItems} items)` : '' }}</h2>
      </div>
      <div class="header-actions">
        <button @click="confirmClearCart" class="clear-cart-btn">
          <svg class="trash-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
            />
          </svg>
          Clear
        </button>
        <button @click="closeCart" class="close-btn" aria-label="Close cart">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>

    <!-- Empty State -->
    <div v-if="!cartItems.length" class="empty-cart">
      <div class="empty-icon">🛒</div>
      <h3>Your cart is empty</h3>
      <p>Add some items to get started</p>
    </div>

    <!-- Cart Content -->
    <div v-else class="cart-content">
      <!-- Cart Items -->
      <div class="cart-items">
        <div v-for="item in cartItems" :key="item.id" class="cart-item">
          <img :src="item.image" :alt="item.name" class="item-image">
          <div class="item-details">
            <div class="item-header">
              <h3 class="item-name">{{ item.name }}</h3>
              <div class="quantity-controls">
                <button @click="decreaseQuantity(item)" class="qty-btn" :disabled="item.quantity <= 1">
                  <svg class="qty-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                  </svg>
                </button>
                <input
                  type="number"
                  v-model.number="item.quantity"
                  @change="updateQuantity(item, $event.target.value)"
                  @input="validateInput($event)"
                  class="qty-input"
                  min="1"
                  max="999"
                >
                <button @click="increaseQuantity(item)" class="qty-btn">
                  <svg class="qty-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="price-row">
              <p class="item-price">KSH {{ formatPrice(item.price) }}</p>
              <p class="item-total">KSH {{ formatPrice(item.price * item.quantity) }}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="cart-summary-section">
      <!-- Cart Summary -->
      <div class="cart-summary">
        <div class="summary-row">
          <span>Subtotal</span>
          <span>KSH {{ formatPrice(cartSubtotal) }}</span>
        </div>
        <div class="summary-row vat-row">
          <span>VAT (Inclusive)</span>
          <span>KSH {{ formatPrice(includedVAT) }}</span>
        </div>
        <div class="summary-row total">
          <span>Total</span>
          <span>KSH {{ formatPrice(cartSubtotal) }}</span>
        </div>
        <button @click="checkout" class="checkout-btn" :disabled="!cartItems.length">
          <span class="checkout-text">Proceed to Checkout</span>
          <span class="checkout-items" v-if="totalItems">
            ({{ totalItems }} {{ totalItems === 1 ? 'item' : 'items' }})
          </span>
        </button>
      </div>
    </div>
  </div>

  <CheckoutPanel 
    :is-open="isCheckoutOpen"
    :cart-items="cartItems"
    :cart-total="cartSubtotal"
    @close="closeCheckout"
    @clear-cart="clearCart"
  />
</template>

<style scoped>
.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
  z-index: 998;
  transition: opacity 0.3s ease;
}

.cart-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 450px;
  height: 100vh;
  background: white;
  z-index: 999;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: -5px 0 25px rgba(0, 0, 0, 0.15);
}

.cart-container.cart-open {
  transform: translateX(0);
}

.cart-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.cart-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
}

.cart-count {
  color: #64748b;
  font-size: 1.1rem;
  font-weight: 500;
}

.clear-cart-btn {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.4rem 0.75rem;
  color: #dc2626;
  background: none;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: all 0.2s ease;
}

.clear-cart-btn:hover {
  background: #fee2e2;
}

.trash-icon {
  width: 16px;
  height: 16px;
}


.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background: #f1f5f9;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.close-btn svg {
  width: 20px;
  height: 20px;
  color: #64748b;
}

.close-btn:hover {
  background: #e2e8f0;
  transform: rotate(90deg);
}

/* Optional: Add animation for icon */
@keyframes shake {
  0%, 100% { transform: rotate(0); }
  25% { transform: rotate(-4deg); }
  75% { transform: rotate(4deg); }
}

.clear-cart-btn:hover .trash-icon {
  animation: shake 0.4s ease;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .cart-header {
    padding: 0.75rem 1rem;
  }

  .cart-header h2 {
    font-size: 1.125rem;
  }

  .cart-count {
    font-size: 1rem;
  }

  .clear-cart-btn {
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
  }

  .trash-icon {
    width: 14px;
    height: 14px;
  }

  .close-btn {
    width: 28px;
    height: 28px;
  }

  .close-btn svg {
    width: 18px;
    height: 18px;
  }
}

.cart-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cart-items {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  padding-bottom: 50px;
  height: 100%;
}

.cart-items::-webkit-scrollbar {
  width: 6px;
}

.cart-items::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.cart-items::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

.cart-item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #e2e8f0;
  transition: transform 0.2s ease;
}

.cart-item:hover {
  transform: translateX(4px);
}

.item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-details {
  flex: 1;
  min-width: 0;
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.item-name {
  margin: 0;
  font-size: .8rem;
  font-weight: bold;
  color: #000000;
  flex: 1;
  /* Text truncation if needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: #f8fafc;
  padding: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
}

.qty-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  cursor: pointer;
  color: #64748b;
  transition: all 0.2s ease;
}

.qty-btn:hover:not(:disabled) {
  background: #f1f5f9;
  color: #1e293b;
  border-color: #cbd5e1;
}

.qty-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.qty-icon {
  width: 16px;
  height: 16px;
}

.qty-input {
  width: 40px;
  height: 28px;
  text-align: center;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #1e293b;
  -moz-appearance: textfield;
  background: white;
  padding: 0 0.25rem;
}

/* Remove spinner arrows */
.qty-input::-webkit-outer-spin-button,
.qty-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.qty-input:focus {
  outline: none;
  border-color: #94a3b8;
  box-shadow: 0 0 0 1px #94a3b8;
}

@media (max-width: 768px) {
  .quantity-controls {
    padding: 0.2rem;
  }

  .qty-btn {
    width: 24px;
    height: 24px;
  }

  .qty-icon {
    width: 14px;
    height: 14px;
  }

  .qty-input {
    width: 36px;
    height: 24px;
    font-size: 0.8125rem;
  }
}

.price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.item-price {
  margin: 0;
  color: #64748b;
  font-size: 0.875rem;
  font-family: var(--font-mono);
}

.item-total {
  margin: 0;
  font-weight: 600;
  color: #1e293b;
  font-size: 0.875rem;
  font-family: var(--font-mono);
}

.cart-summary {
  padding: 1.5rem;
  background: #f8fafc;
  border-top: 1px solid #e2e8f0;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  color: #64748b;
  font-size: 0.9375rem;
}

.vat-row {
  color: #94a3b8;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.vat-row span:last-child {
  font-family: var(--font-mono);
}

.summary-row.total {
  color: #1e293b;
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0;
  border-bottom: none;
}

.checkout-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  background: #16a34a;
  color: white;
  border: none;
  border-radius: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1rem;
  margin-top: 1.5rem;
  box-shadow: 0 2px 4px rgba(22, 163, 74, 0.2);
}

.checkout-btn:hover:not(:disabled) {
  background: #15803d;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(22, 163, 74, 0.2);
}

.checkout-btn:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(22, 163, 74, 0.2);
}

.checkout-btn:disabled {
  background: #94a3b8;
  cursor: not-allowed;
  box-shadow: none;
}

.checkout-text {
  font-weight: 600;
}

.checkout-items {
  font-size: 0.9em;
  font-weight: 500;
  opacity: 0.9;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .checkout-btn {
    padding: 0.875rem;
    font-size: 0.9375rem;
    margin-top: 1rem;
  }

  .checkout-items {
    font-size: 0.85em;
  }
}

/* Optional: Add loading state styles */
.checkout-btn.loading {
  position: relative;
  background: #15803d;
  cursor: wait;
}

.checkout-btn.loading::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.8s linear infinite;
  margin-left: 0.5rem;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  text-align: center;
  height: 100%;
}

.empty-icon {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  animation: bounce 2s infinite;
}

.empty-cart h3 {
  margin: 0 0 0.75rem 0;
  font-size: 1.25rem;
  color: #1e293b;
  font-weight: 600;
}

.empty-cart p {
  margin: 0;
  color: #64748b;
  font-size: 0.875rem;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@media (max-width: 768px) {
  .cart-container {
    width: 100%;
  }
  
  .cart-header {
    padding: 0.5rem 1rem;
  }
  
  .cart-header h2 {
    font-size: 1.125rem;
  }
  
  .item-image {
    width: 70px;
    height: 70px;
  }
  
  .cart-summary {
    padding: 1rem;
  }

  .item-header {
    gap: 0.5rem;
  }

  .quantity-controls {
    padding: 0.2rem;
  }

  .qty-btn {
    width: 22px;
    height: 22px;
  }

  .item-name {
    font-size: 0.875rem;
  }
}

.summary-row span:last-child {
  font-family: var(--font-mono);
  font-weight: 500;
}

.summary-row.total span:last-child {
  font-weight: 600;
}

.cart-item-price,
.cart-item-total,
.subtotal-amount,
.tax-amount,
.total-amount {
  font-family: var(--font-mono);
}

.cart-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.clear-cart-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background: #FEE2E2;
  color: #DC2626;
  border: 1px solid #DC2626;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.clear-cart-btn:hover {
  background: #DC2626;
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(220, 38, 38, 0.2);
}

.clear-cart-btn:active {
  transform: translateY(0);
  box-shadow: none;
}

.trash-icon {
  width: 18px;
  height: 18px;
  transition: transform 0.2s ease;
}

.clear-cart-btn:hover .trash-icon {
  transform: rotate(-4deg);
}

/* Optional: Add animation for icon */
@keyframes shake {
  0%, 100% { transform: rotate(0); }
  25% { transform: rotate(-4deg); }
  75% { transform: rotate(4deg); }
}

.clear-cart-btn:hover .trash-icon {
  animation: shake 0.4s ease;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .clear-cart-btn {
    padding: 0.4rem 0.6rem;
    font-size: 0.8rem;
  }

  .trash-icon {
    width: 16px;
    height: 16px;
  }
}

.cart-summary-section {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: white;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.05);
  z-index: 1;
}
</style>

<script>
import CheckoutPanel from './CheckoutPanel.vue'

export default {
  name: 'ShoppingCart',
  components: {
    CheckoutPanel
  },
  
  data() {
    return {
      isCartOpen: false,
      isMobile: false,
      isCheckoutOpen: false
    }
  },

  computed: {
    cartItems() {
      return this.$store.state.cartItems || []
    },
    totalItems() {
      return this.cartItems.reduce((total, item) => total + item.quantity, 0)
    },
    cartSubtotal() {
      return this.cartItems.reduce((total, item) => total + (item.price * item.quantity), 0)
    },
    includedVAT() {
      // Calculate VAT that was included in the total (16%)
      return (this.cartSubtotal * 0.16) / 1.16
    }
  },

  mounted() {
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
    // Add escape key listener
    document.addEventListener('keydown', this.handleEscKey)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile)
    document.removeEventListener('keydown', this.handleEscKey)
  },

  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768
    },

    closeCart() {
      console.log('Closing cart') // Debug log
      this.isCartOpen = false
      document.body.style.overflow = ''
    },

    toggleCart() {
      this.isCartOpen = !this.isCartOpen
      document.body.style.overflow = this.isCartOpen ? 'hidden' : ''
    },

    handleEscKey(e) {
      if (e.key === 'Escape' && this.isCartOpen) {
        this.closeCart()
      }
    },

    formatPrice(price) {
      return price.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    validateInput(event) {
      // Remove non-numeric characters
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
      
      // Ensure value is within bounds
      let value = parseInt(event.target.value) || 0;
      if (value > 999) {
        event.target.value = '999';
      }
      if (value < 1) {
        event.target.value = '1';
      }
    },

    updateQuantity(item, newQuantity) {
      // Convert to number and validate
      newQuantity = parseInt(newQuantity) || 1;
      
      // Ensure quantity is within bounds
      newQuantity = Math.max(1, Math.min(999, newQuantity));
      
      // Update store
      this.$store.dispatch('updateCartItemQuantity', {
        id: item.id,
        quantity: newQuantity
      });
    },

    increaseQuantity(item) {
      if (item.quantity < 999) {
        this.updateQuantity(item, item.quantity + 1);
      }
    },

    decreaseQuantity(item) {
      if (item.quantity > 1) {
        this.updateQuantity(item, item.quantity - 1);
      }
    },

    confirmClearCart() {
      if (confirm('Are you sure you want to clear your cart?')) {
        this.$store.dispatch('clearCart')
      }
    },

    checkout() {
      if (this.cartItems.length) {
        this.isCheckoutOpen = true;
        this.closeCart();
      }
    },

    closeCheckout() {
      this.isCheckoutOpen = false;
    },

    clearCart() {
      this.$store.dispatch('clearCart');
    }
  }
}
</script> 