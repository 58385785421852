
export const auth = {
  async login() {
    try {
      const loginData = {
        email: "customer@kjsliqour.shop",
        password: "customer@kjsliqour.shop"
      };
  
      const response = await fetch('https://kjsliqour.shop/api/auth/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': ''
        },
        body: JSON.stringify(loginData)
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const result = await response.json();
      
      if (result.token) {
        localStorage.setItem('auth_token', result.token);
        return result.token;
      }

      return null;
    } catch (error) {
      console.error('Login error:', error);
      return null;
    }
  },

  getToken() {
    return localStorage.getItem('auth_token');
  },

  isLoggedIn() {
    return !!this.getToken();
  }
};
