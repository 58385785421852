<template>
  <div 
    class="notification-container"
    :class="{ 'show': isVisible }"
  >
    <div class="notification success">
      <div class="notification-content">
        <svg class="check-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        <span class="message">{{ message }}</span>
      </div>
      <button @click="close" class="close-button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessNotification',
  props: {
    message: {
      type: String,
      default: 'Order submitted successfully!'
    },
    duration: {
      type: Number,
      default: 5000
    }
  },
  data() {
    return {
      isVisible: false,
      timeout: null
    }
  },
  methods: {
    show() {
      this.isVisible = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.close, this.duration);
    },
    close() {
      this.isVisible = false;
      if (this.timeout) clearTimeout(this.timeout);
    }
  },
  beforeUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }
}
</script>

<style scoped>
.notification-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
  transform: translateX(150%);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.notification-container.show {
  transform: translateX(0);
}

.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background: #10b981;
  color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  min-width: 300px;
  max-width: 500px;
}

.notification-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.check-icon {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}

.message {
  font-weight: 500;
  line-height: 1.25;
}

.close-button {
  background: transparent;
  border: none;
  color: white;
  padding: 0.25rem;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.close-button:hover {
  opacity: 1;
}

.close-button svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* Animation for the check icon */
.check-icon {
  animation: checkmark 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

@keyframes checkmark {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style> 