<template>
  <div v-if="product" class="product-card">
    <div class="product-image-container">
      <img 
        :src="getImageUrl"
        :alt="product.name"
        class="product-image"
        @error="handleImageError"
      >
    </div>
    
    <div class="product-info">
      <h3 class="product-name">{{ formatProductName(product.name) }}</h3>
      <div class="price-quantity-row">
        <p class="product-price">
          <span class="currency">KSH</span>
          {{ displayPrice }}
        </p>
        <div class="quantity-control">
          <button 
            @click="updateQuantity('decrease')" 
            class="quantity-btn minus"
            :disabled="currentQuantity === 0"
          >
            <span class="icon">−</span>
          </button>
          <input
            type="number"
            v-model.number="currentQuantity"
            class="quantity-input"
            min="0"
            :max="999"
          >
          <button 
            @click="updateQuantity('increase')" 
            class="quantity-btn plus"
          >
            <span class="icon">+</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentQuantity: 0
    }
  },
  computed: {
    displayPrice() {
      // Use wholesale_price if available, otherwise use regular price
      const price = this.product.wholesale_price || this.product.price;
      return this.formatPrice(price);
    },
    getImageUrl() {
      if (this.product.formattedImage) return this.product.formattedImage;
      if (!this.product.cover) return '/placeholder.jpg';
      return `https://kjsliqour.shop/${this.product.cover}`;
    }
  },
  methods: {
    formatPrice(price) {
      if (!price) return '0.00';
      
      // Convert to string and clean the price
      const priceStr = String(price);
      const cleanPrice = priceStr.replace(/[Ksh.,\s]/gi, '').trim();
      
      // Parse to float and handle invalid numbers
      const numPrice = parseFloat(cleanPrice);
      if (isNaN(numPrice)) return '0.00';
      
      return numPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    updateQuantity(action) {
      if (action === 'increase') {
        this.currentQuantity++;
      } else if (action === 'decrease' && this.currentQuantity > 0) {
        this.currentQuantity--;
      }
      
      if (this.currentQuantity === 0) {
        this.$store.dispatch('removeFromCart', this.product.id);
      } else {
        // Get the raw price value
        const rawPrice = this.product.wholesale_price || this.product.price;
        
        // Clean and parse the price
        const priceStr = String(rawPrice);
        const cleanPrice = priceStr.replace(/[Ksh.,\s]/gi, '').trim();
        const finalPrice = parseFloat(cleanPrice);

        // Create cart item with both numeric and formatted price
        const cartItem = {
          id: this.product.id,
          name: this.product.name,
          price: finalPrice, // Numeric price for calculations
          formattedPrice: this.displayPrice, // Formatted price for display
          image: this.getImageUrl, // Use the computed image URL
          quantity: this.currentQuantity
        };

        // Debug log
        console.log('Adding to cart:', {
          rawPrice,
          cleanPrice,
          finalPrice,
          cartItem
        });

        this.$store.dispatch('addToCart', cartItem);
      }
    },
    handleImageError(e) {
      e.target.src = '/placeholder.jpg'
    },
    formatProductName(name) {
      if (!name) return '';
      // Convert to sentence case and trim
      return name;
      //return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }
  },
  watch: {
    '$store.state.cartItems': {
      handler(items) {
        const cartItem = items.find(item => item.id === this.product.id)
        this.currentQuantity = cartItem ? cartItem.quantity : 0
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style scoped>
.product-card {
  background: white;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  transition: all 0.2s ease-in-out;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(148, 163, 184, 0.05);
  position: relative;
  overflow: hidden;
}

.product-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(148, 163, 184, 0.1),
              0 2px 4px rgba(148, 163, 184, 0.06);
  border-color: #cbd5e1;
}

.product-card:active {
  transform: translateY(0);
}

.product-image-container {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
  background: #f8fafc;
  position: relative;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.product-card:hover .product-image {
  transform: scale(1.05);
}

.product-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  padding: 0.25rem 0;
}

.product-name {
  font-size: 1rem;
  color: #202020;
  margin: 0.5rem 0;
  line-height: 1.4;
  font-weight: bold;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  max-width: 100%;
  /* Limit to 2 lines with ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-price {
  margin: 0;
  color: #64748b;
  font-family: var(--font-mono);
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  white-space: nowrap;
}

.currency {
  font-size: 0.75rem;
  font-weight: 600;
  color: #94a3b8;
}

.quantity-control {
  display: inline-flex;
  align-items: center;
  background: #f8fafc;
  border-radius: 10px;
  padding: 0.3rem;
  border: 1px solid #e2e8f0;
  width: fit-content;
  flex-shrink: 0;
  box-shadow: 0 1px 2px rgba(148, 163, 184, 0.05);
}

.quantity-btn {
  background: white;
  border: 1px solid #e2e8f0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  color: #64748b;
  flex-shrink: 0;
}

.quantity-btn .icon {
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 600;
}

.quantity-btn.minus .icon {
  color: #ef4444;
}

.quantity-btn.plus .icon {
  color: #22c55e;
}

.quantity-btn:hover {
  background: #f1f5f9;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(148, 163, 184, 0.1);
}

.quantity-btn:active {
  transform: translateY(0);
  background: #e2e8f0;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #f8fafc;
  transform: none;
  box-shadow: none;
}

.quantity-input {
  width: 45px;
  height: 32px;
  text-align: center;
  border: 1px solid #e2e8f0;
  background: white;
  border-radius: 8px;
  margin: 0 0.3rem;
  padding: 0;
  font-family: var(--font-mono);
  font-size: 0.95rem;
  font-weight: 500;
  color: #1e293b;
  -moz-appearance: textfield;
  transition: all 0.2s ease;
}

.quantity-input::-webkit-outer-spin-button,
.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-input:focus {
  outline: none;
  border-color: #94a3b8;
  box-shadow: 0 0 0 3px rgba(148, 163, 184, 0.1);
}

.price-quantity-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 0.25rem;
}

/* Responsive styles */
@media (max-width: 768px) {
    .cart-button{
        display: block;
    }
  .product-card {
    padding: 0.875rem;
    gap: 1rem;
    min-width: 20em;
  }

  .product-image-container {
    width: 70px;
    height: 70px;
  }

  .product-name {
    font-size: 1rem;
  }

  .product-price {
    font-size: 0.95rem;
  }

  .quantity-control {
    padding: 0.25rem;
  }

  .quantity-btn {
    width: 28px;
    height: 28px;
  }

  .quantity-input {
    width: 40px;
    height: 28px;
    font-size: 0.9rem;
  }

  .price-quantity-row {
    gap: 0.75rem;
  }

  .product-price {
    font-size: 0.95rem;
  }
}

/* For very small screens */
@media (max-width: 360px) {
    .cart-button{
        display: block;
    }
  .product-card {
    padding: 0.75rem;
    gap: 0.75rem;
    min-width: 18em;
  }

  .product-image-container {
    width: 60px;
    height: 60px;
  }

  .quantity-control {
    transform: scale(0.9);
    transform-origin: right center;
  }

  .price-quantity-row {
    gap: 0.5rem;
  }

  .product-price {
    font-size: 0.9rem;
  }
}


</style> 