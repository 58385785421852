export const regions = [
  'Westlands',
  //'Parklands',
//   'Kitisuru',
  'Spring Valley',
  'Loresho',
//   'Lower Kabete',
//   'Upper Kabete',
  'Mountain View',
  'Riverside Drive',
 // 'Kyuna',
//   'Sarit Centre',

  'Kilimani',
  'Yaya Centre',
  'Adams Arcade',
  'Prestige Plaza',
  'Ngong Road',
  'Hurlingham',
  'Jamhuri',
  'Dagoretti Corner',
  'Kawangware',
  'Waithaka',
  'Lavington',
  'Kibera',
  'Valley Arcade',
  'Denis Pritt Road',
  'Kileleshwa',

  'Karen',
  'Langata',
  'Ngong Town',
  'Hardy',
  'The Hub Karen',
  'Bomas of Kenya',
  'Ongata Rongai',
  'Mbagathi',

  'Kikuyu',
  'Kinoo',
  'Muthiga',
  'Gitaru',
  '87 Market',
  'Zambezi',
  'Uthiru',
  'Muguga',

  'Upper Hill',
//   'Community',
//   'Ngara',
//   'CBD (Central Business District)',
//   'South B',
//   'South C',
//   'Industrial Area',
//   'Nairobi West',

//   'Two Rivers Mall',
//   'Village Market',
//   'Rosslyn',
//   'Runda'
]; 