export const utils = {
  formatPrice(price) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'KES'
    }).format(price);
  },

  getImageUrl(cover) {
    if (!cover) return '';
    // If the cover is already a full URL, return it
    if (cover.startsWith('http')) return cover;
    // Otherwise, construct the URL (adjust the base URL as needed)
    return `https://kjsliqour.shop/storage/${cover}`;
  }
}; 