<template>
  <div class="product-section">
    <!-- Search and Filter Section -->
    <div class="filters-section">
      <!-- Search Bar -->
      <div class="mobile-search">
        <div class="search-wrapper">
          <input
            type="text"
            :value="searchQuery"
            @input="updateSearch"
            placeholder="Search products..."
            class="search-input"
          >
          <button 
            v-if="searchQuery" 
            @click="clearSearch" 
            class="clear-search"
            aria-label="Clear search"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="clear-svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>

      <!-- Categories Filter -->
      <div class="categories-container">
        <div class="categories-list">
          <button 
            class="category-btn"
            :class="{ active: selectedCategory === null }"
            @click="selectCategory(null)"
          >
            All
          </button>
          <button 
            v-for="category in categories" 
            :key="category.id"
            class="category-btn"
            :class="{ active: selectedCategory === category.slug }"
            @click="selectCategory(category.slug)"
          >
            {{ category.name }}
          </button>
        </div>
      </div>
    </div>

    <!-- Loading state -->
    <div v-if="loading" class="loading-state">
      <div class="spinner"></div>
      <span>Loading products...</span>
    </div>

    <!-- Error state -->
    <div v-else-if="error" class="error-state">
      <svg class="error-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
      </svg>
      {{ error }}
    </div>

    <!-- Products grid -->
    <div v-else class="products-grid">
      <template v-if="filteredProducts && filteredProducts.length > 0">
        <ProductCard
          v-for="product in filteredProducts"
          :key="product.id"
          :product="product"
        />
      </template>
      
      <!-- No products found -->
      <div v-else class="no-results">
        <div class="no-results-content">
          <span class="search-term">"{{ searchQuery }}"</span>
          <p>No products found matching your search</p>
          <p class="request-text">Would you like to request this product to be stocked?</p>
          <a 
            :href="whatsappLink" 
            target="_blank" 
            rel="noopener noreferrer" 
            class="whatsapp-button"
          >
            <svg class="whatsapp-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824-.299.045-.677.063-1.092-.069-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446c.159-.173.346-.217.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.173.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824z"/>
            </svg>
            Request Via WhatsApp
          </a>
        </div>
      </div>
    </div>

    <!-- Add this before closing the products-grid div -->
    <div v-if="pagination.last_page > 1" class="pagination">
      <button 
        :disabled="pagination.current_page === 1"
        @click="changePage(pagination.current_page - 1)"
        class="page-btn"
      >
        Previous
      </button>
      
      <button
        v-for="page in pagination.last_page"
        :key="page"
        :class="['page-btn', { active: page === pagination.current_page }]"
        @click="changePage(page)"
      >
        {{ page }}
      </button>
      
      <button 
        :disabled="pagination.current_page === pagination.last_page"
        @click="changePage(pagination.current_page + 1)"
        class="page-btn"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ProductCard from './ProductCard.vue'

export default {
  name: 'ProductSection',
  components: {
    ProductCard
  },
  
  data() {
    return {
      searchTimeout: null,
      selectedCategory: null,
      pagination: {
        current_page: 1,
        last_page: 1,
        per_page: 30,
        total: 0
      }
    }
  },
  
  computed: {
    ...mapState(['searchQuery', 'loading', 'error', 'categories']),
    ...mapGetters(['filteredProducts']),
    
    whatsappLink() {
      const message = encodeURIComponent(
        `Hello! I would like to request "${this.searchQuery}" to be stocked in your store. Please let me know when it becomes available.`
      );
      return `https://wa.me/254707491515?text=${message}`;
    }
  },

  methods: {
    ...mapActions(['fetchProducts', 'fetchCategories']),
    
    updateSearch(event) {
      this.$store.commit('SET_SEARCH_QUERY', event.target.value);
    },
    
    clearSearch() {
      this.$store.commit('SET_SEARCH_QUERY', '');
    },

    selectCategory(categoryId) {
      this.selectedCategory = categoryId;
      this.pagination.current_page = 1;
      
      this.fetchProducts({ 
        category: categoryId,
        searchQuery: this.searchQuery,
        page: this.pagination.current_page
      });
    },
    
    async changePage(page) {
      if (page === this.pagination.current_page) return;
      
      this.pagination.current_page = page;
      await this.fetchProducts({ 
        category: this.selectedCategory,
        searchQuery: this.searchQuery,
        page: page
      });
    },

    async handleSearch() {
      try {
        this.pagination.current_page = 1; // Reset to first page on new search
        await this.fetchProducts({ 
          searchQuery: this.searchQuery,
          category: this.selectedCategory,
          page: this.pagination.current_page
        });
      } catch (error) {
        console.error('Error searching products:', error);
      }
    },

    updatePagination(response) {
      this.pagination = {
        current_page: response.current_page,
        last_page: response.last_page,
        per_page: response.per_page,
        total: response.total
      };
    }
  },

  watch: {
    searchQuery: {
      handler() {
        this.pagination.current_page = 1;
        
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        
        this.searchTimeout = setTimeout(() => {
          this.handleSearch();
        }, 300);
      }
    },
    
    '$store.state.products'(newProducts) {
      if (newProducts && this.$store.state.paginationData) {
        this.updatePagination(this.$store.state.paginationData);
      }
    }
  },

  async created() {
    try {
      await Promise.all([
        this.fetchCategories(),
        this.fetchProducts()
      ]);
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  }
}
</script>

<style scoped>


.products-grid {
  display: grid;
  gap: 1.5rem;
  padding: 1rem;
  
  /* Mobile: 1 product per row */
  grid-template-columns: 1fr;
  
  /* Tablet: 2 products per row */
  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  /* Desktop: 3 products per row */
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
    margin: 0 auto;
  }
}

.loading-state,
.error-state,
.no-results {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1.1rem;
}

.error-state {
  color: #dc2626;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.error-icon {
  width: 3rem;
  height: 3rem;
  color: #dc2626;
}

.loading-state {
  text-align: center;
  padding: 2rem;
  color: #16a34a;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #e5e7eb;
  border-radius: 50%;
  border-top-color: #16a34a;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.filters-section {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}

.mobile-search {
  flex: 1;
  margin: 0 1rem;
  max-width: 400px;
  width: 100%;
}

.search-wrapper {
  position: relative;
  width: 100%;
  background: #f3f4f6;
  border-radius: 50px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  border: 1px solid #e5e7eb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.search-wrapper:hover {
  background: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
}

.search-wrapper:focus-within {
  background: #ffffff;
  border-color: #16a34a;
  box-shadow: 0 0 0 4px rgba(34, 197, 94, 0.1);
}

.search-input {
  width: 100%;
  padding: 0.875rem 1.5rem;
  padding-right: 3rem;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  color: #374151;
  background: transparent;
  outline: none;
}

.search-input::placeholder {
  color: #6d6d6d;
  font-size: 1rem;
}

.clear-search {
  position: absolute;
  right: 1.25rem;
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.clear-search:hover {
  background-color: #e5e7eb;
  color: #ef4444;
}

.clear-svg {
  width: 1.25rem;
  height: 1.25rem;
}

.categories-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0rem;
}

.categories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
  padding: 0.5rem;
}

.category-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 50px;
  background: white;
  color: #374151;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.category-btn:hover {
  background: #f3f4f6;
}

.category-btn.active {
  background: #16a34a;
  color: white;
  border-color: #16a34a;
}

/* Remove the horizontal scrollbar styles */
.categories-container::-webkit-scrollbar {
  display: none;
}

.categories-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .categories-list {
    gap: 0.5rem;
  }
  
  .category-btn {
    padding: 0.375rem 0.75rem;
    font-size: 0.8125rem;
  }
}

/* Hide product search on mobile */
@media (max-width: 768px) {
  .mobile-search {
    display: none;
  }
}

/* Show product search on desktop */
@media (min-width: 769px) {
  .mobile-search {
    display: block;
  }
}

.no-results {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1; /* Span all columns */
}

.no-results-content {
  text-align: center;
  color: #6b7280;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-term {
  font-weight: 600;
  color: #16a34a;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.no-results-content p {
  margin-top: 0.5rem;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.request-text {
  margin-top: 1rem;
  color: #4b5563;
  font-size: 1.1rem;
}

.whatsapp-button {
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 1.5rem;
  background-color: #25D366;
  color: white;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(37, 211, 102, 0.2);
}

.whatsapp-button:hover {
  background-color: #128C7E;
  box-shadow: 0 4px 6px rgba(37, 211, 102, 0.3);
  transform: translateY(-1px);
}

.whatsapp-icon {
  width: 3.5rem;
  height: 3.5rem;
}

.pagination {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
  padding: 1rem;
}

.page-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  background: white;
  color: #374151;
  cursor: pointer;
  transition: all 0.2s ease;
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-btn:hover:not(:disabled) {
  background: #f3f4f6;
}

.page-btn.active {
  background: #16a34a;
  color: white;
  border-color: #16a34a;
}
</style> 