<template>
  <div class="cart-section">
    <div class="cart-header">
      <h2>Shopping Cart</h2>
      <div class="cart-actions" v-if="cartItems.length">
        <span class="cart-count">{{ totalItems }} items</span>
        <button @click="confirmClearCart" class="clear-cart-btn">
          <span class="clear-icon">🗑</span>
          Clear Cart
        </button>
      </div>
    </div>
    
    <div v-if="!cartItems.length" class="empty-cart">
      <div class="empty-cart-icon">🛒</div>
      <h3>Your cart is empty</h3>
      <p>Add some items to your cart to get started</p>
    </div>
    
    <div v-else class="cart-items">
      <div v-for="item in cartItems" :key="item.id" class="cart-item">
        <img :src="item.image" :alt="item.name" class="cart-item-image">
        <div class="cart-item-details">
          <div class="item-header">
            <h3>{{ item.name }}</h3>
            <div class="quantity-control">
              <button 
                @click="decreaseQuantity(item)"
                class="quantity-btn minus"
                :disabled="item.quantity === 0"
              >
                <span class="icon">−</span>
              </button>
              <input
                type="number"
                v-model.number="item.quantity"
                @change="updateQuantity(item, $event.target.value)"
                @input="validateInput"
                class="quantity-input"
                min="0"
                :max="999"
              >
              <button 
                @click="increaseQuantity(item)"
                class="quantity-btn plus"
              >
                <span class="icon">+</span>
              </button>
            </div>
          </div>
          <div class="price-row">
            <p class="cart-item-price">Ksh {{ formatPrice(item.price) }}</p>
            <p class="cart-item-total">Ksh {{ formatPrice(item.price * item.quantity) }}</p>
          </div>
        </div>
      </div>
      
      <div class="cart-summary">
        <div class="summary-row">
          <span>Subtotal</span>
          <span>Ksh {{ formatPrice(cartSubtotal) }}</span>
        </div>
        <div class="summary-row">
          <span>VAT (16%)</span>
          <span>Ksh {{ formatPrice(vatAmount) }}</span>
        </div>
        <div class="summary-row total">
          <span>Total</span>
          <span>Ksh {{ formatPrice(cartTotal) }}</span>
        </div>
        <button 
          @click="openCheckout" 
          class="checkout-btn" 
          :disabled="!cartItems.length"
        >
          Checkout ({{ totalItems }} items)
        </button>
      </div>
    </div>

    <CheckoutPanel 
      :is-open="isCheckoutOpen"
      :cart-items="cartItems"
      :cart-total="cartTotal"
      @close="closeCheckout"
    />
  </div>
</template>

<script>
import CheckoutPanel from './CheckoutPanel.vue'

export default {
  name: 'CartSection',
  components: {
    CheckoutPanel
  },
  data() {
    return {
      isCheckoutOpen: false
    }
  },
  computed: {
    cartItems() {
      return this.$store.state.cartItems
    },
    totalItems() {
      return this.cartItems.reduce((total, item) => total + item.quantity, 0)
    },
    cartSubtotal() {
      return this.cartItems.reduce((total, item) => total + (item.price * item.quantity), 0)
    },
    vatAmount() {
      return this.cartSubtotal * 0.16
    },
    cartTotal() {
      return this.cartSubtotal
    }
  },
  methods: {
    increaseQuantity(item) {
      this.$store.dispatch('addToCart', {
        ...item,
        quantity: item.quantity + 1
      })
    },
    decreaseQuantity(item) {
      if (item.quantity > 0) {
        const newQuantity = item.quantity - 1
        if (newQuantity === 0) {
          this.removeItem(item.id)
        } else {
          this.$store.dispatch('addToCart', {
            ...item,
            quantity: newQuantity
          })
        }
      }
    },
    removeItem(itemId) {
      this.$store.dispatch('removeFromCart', itemId)
    },
    formatPrice(price) {
      return parseFloat(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    checkout() {
      console.log('Checkout clicked')
    },
    updateQuantity(item, newQuantity) {
      // Convert to number and validate
      newQuantity = parseInt(newQuantity) || 0
      
      // Ensure quantity is within bounds
      newQuantity = Math.max(0, Math.min(999, newQuantity))
      
      if (newQuantity === 0) {
        this.$store.dispatch('removeFromCart', item.id)
      } else {
        this.$store.dispatch('addToCart', {
          ...item,
          quantity: newQuantity
        })
      }
    },
    
    validateInput(event) {
      // Remove non-numeric characters
      event.target.value = event.target.value.replace(/[^0-9]/g, '')
      
      // Limit to 3 digits
      if (event.target.value.length > 3) {
        event.target.value = event.target.value.slice(0, 3)
      }
    },
    confirmClearCart() {
      if (confirm('Are you sure you want to clear your cart?')) {
        this.$store.dispatch('clearCart')
      }
    },
    openCheckout() {
      if (this.cartItems.length) {
        this.isCheckoutOpen = true
        document.body.style.overflow = 'hidden'
      }
    },
    closeCheckout() {
      this.isCheckoutOpen = false
      document.body.style.overflow = ''
    }
  }
}
</script>

<style scoped>
.cart-section {
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  font-family: var(--font-primary);
}

.cart-title {
  font-size: 1.25rem;
  margin: 0 0 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
}

.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  text-align: center;
  background: #f8f9fa;
  border-radius: 12px;
  margin-top: 1rem;
}

.empty-cart-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
  animation: bounce 2s infinite;
  color: #6c757d;
}

.empty-cart h3 {
  margin: 0;
  color: #2c3e50;
  font-family: var(--font-primary);
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.empty-cart p {
  margin: 0;
  color: #6c757d;
  font-family: var(--font-primary);
  font-size: 0.9rem;
}

.cart-items {
  flex: 1;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.cart-item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease;
}

.cart-item:hover {
  background-color: #f8f9fa;
}

.cart-item-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 8px;
  flex-shrink: 0;
}

.cart-item-details {
  flex: 1;
  min-width: 0; /* Prevents flex item from overflowing */
}

.cart-item-details h3 {
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 1rem;
  color: #2c3e50;
  margin: 0;
  /* Text wrapping styles */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  max-height: 2.8em;
}

.cart-item-price {
  color: #666;
  font-family: var(--font-mono);
  font-size: 0.9rem;
}

.quantity-control {
  display: inline-flex;
  align-items: center;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 0.25rem;
  border: 1px solid #dee2e6;
  width: fit-content;
}

.quantity-input {
  width: 40px;
  height: 28px;
  text-align: center;
  border: none;
  background: white;
  border-radius: 4px;
  margin: 0 0.25rem;
  padding: 0;
  font-family: var(--font-mono);
  font-size: 0.9rem;
  font-weight: 500;
  color: #495057;
  -moz-appearance: textfield; /* Removes spinner in Firefox */
}

/* Remove spinner buttons in Chrome, Safari, Edge, Opera */
.quantity-input::-webkit-outer-spin-button,
.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
}

/* Style for when input is invalid */
.quantity-input:invalid {
  border-color: #dc3545;
  box-shadow: none;
}

.quantity-btn {
  background: none;
  border: none;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  color: #495057;
  flex-shrink: 0;
}

.quantity-btn:hover {
  background: #e9ecef;
}

.quantity-btn:active {
  background: #dee2e6;
  transform: scale(0.95);
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: none;
}

.quantity-btn .icon {
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 600;
}

.quantity-btn.minus .icon {
  color: #dc3545;
}

.quantity-btn.plus .icon {
  color: #28a745;
}

.quantity-display {
  min-width: 32px;
  text-align: center;
  font-weight: 500;
  color: #495057;
  padding: 0.25rem;
  background: white;
  border-radius: 4px;
  margin: 0 0.25rem;
  font-family: var(--font-mono);
  user-select: none;
  flex-shrink: 0;
}

.cart-item-total {
  margin: 0;
  font-weight: 600;
  color: #2c3e50;
  font-family: var(--font-mono);
  margin-left: auto;
}

.cart-summary {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 12px;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  font-family: var(--font-primary);
}


.summary-row span:first-child {
  color: #666;
}

.summary-row span:last-child {
  font-family: var(--font-mono);
  font-weight: 500;
}

.summary-row.total {
  font-weight: 700;
  color: #2c3e50;
  font-size: 1.1rem;
  padding-top: 1rem;
  margin-top: 0.5rem;
  border-top: 2px solid #dee2e6;
}

.checkout-btn {
  width: 100%;
  padding: 1rem;
  background: #42b983;
  color: white;
  border: none;
  border-radius: 8px;
  font-family: var(--font-primary);
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1.5rem;
}

.checkout-btn:hover {
  background: #3aa876;
  transform: translateY(-1px);
}

.checkout-btn:active {
  transform: translateY(0);
}

.empty-cart {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-family: var(--font-primary);
  background: #f8f9fa;
  border-radius: 8px;
  margin-top: 1rem;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.cart-header h2 {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0;
  color: #2c3e50;
}

.cart-count {
  font-family: var(--font-mono);
  font-weight: 500;
  color: #666;
  background: #f8f9fa;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.9rem;
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.item-header h3 {
  margin: 0;
  font-size: 1rem;
  color: #2c3e50;
  font-family: var(--font-primary);
  font-weight: 600;
}

.price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.cart-item-price {
  color: #666;
  font-family: var(--font-mono);
  font-size: 0.9rem;
  margin: 0;
}

.cart-item-total {
  margin: 0;
  font-weight: 600;
  color: #2c3e50;
  font-family: var(--font-mono);
}

.quantity-control {
  display: inline-flex;
  align-items: center;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 0.25rem;
  border: 1px solid #dee2e6;
  width: fit-content;
}

.quantity-btn {
  background: none;
  border: none;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  color: #495057;
  flex-shrink: 0;
}

.quantity-btn .icon {
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 600;
}

.quantity-btn.minus .icon {
  color: #dc3545;
}

.quantity-btn.plus .icon {
  color: #28a745;
}

.quantity-btn:hover {
  background: #e9ecef;
}

.quantity-btn:active {
  background: #dee2e6;
  transform: scale(0.95);
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: none;
}

.quantity-display {
  min-width: 32px;
  text-align: center;
  font-weight: 500;
  color: #495057;
  padding: 0.25rem;
  background: white;
  border-radius: 4px;
  margin: 0 0.25rem;
  font-family: var(--font-mono);
  user-select: none;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .cart-section {
    padding: 0.75rem;
  }
  
  .cart-item {
    padding: 0.75rem;
  }
  
  .cart-item-image {
    width: 60px;
    height: 60px;
  }

  .quantity-control {
    min-width: 100px;
  }

  .quantity-btn {
    width: 28px;
    height: 28px;
  }

  .quantity-display {
    min-width: 32px;
    font-size: 0.9rem;
  }

  .cart-header h2 {
    font-size: 1.25rem;
  }
  
  .cart-count {
    font-size: 0.8rem;
  }
  
  .cart-summary {
    padding: 1rem;
  }
  
  .summary-row {
    padding: 0.5rem 0;
    font-size: 0.9rem;
  }
  
  .checkout-btn {
    padding: 0.875rem;
    font-size: 1rem;
  }

  .remove-btn {
    top: 0.75rem;
    right: 0.75rem;
    font-size: 0.8rem;
  }

  .price-quantity-row {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .cart-item-price {
    min-width: auto;
    flex: 1;
  }

  .quantity-control {
    order: 3;
    width: 100%;
    justify-content: center;
  }

  .cart-item-total {
    flex: 1;
    text-align: right;
  }

  .quantity-input {
    width: 36px;
    height: 26px;
    font-size: 0.85rem;
  }

  .quantity-btn {
    width: 26px;
    height: 26px;
  }
}

/* Animation for removal */
.cart-item {
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Optional: Add animation for when items are removed */
.cart-item.removing {
  animation: slideOut 0.3s ease forwards;
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(10px);
  }
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.cart-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cart-count {
  color: #666;
  font-size: 0.9rem;
  font-family: var(--font-primary);
}

.clear-cart-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background: #fff;
  border: 1px solid #dc3545;
  color: #dc3545;
  border-radius: 6px;
  font-family: var(--font-primary);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.clear-cart-btn:hover {
  background: #dc3545;
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(220, 53, 69, 0.2);
}

.clear-cart-btn:active {
  transform: translateY(0);
  box-shadow: none;
}

.clear-icon {
  font-size: 1rem;
  line-height: 1;
}

@media (max-width: 768px) {
  .cart-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    margin-bottom: 1rem;
    padding-bottom: 0.75rem;
  }

  .cart-actions {
    width: 100%;
    justify-content: space-between;
  }

  .clear-cart-btn {
    padding: 0.4rem 0.6rem;
    font-size: 0.8rem;
  }

  .cart-count {
    font-size: 0.8rem;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style> 